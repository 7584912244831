<template>
  <div id="page">
    <PageHeader
      :pageTitle="pageTitle"
      :pageDescription="pageDescription"
    ></PageHeader>

    <v-row>
      <v-col cols="8">
        <DataTable
          @tableAction="emittedAction"
          :items="items"
          :headers="headers"
          :itemActions="{
            edit: true,
          }"
          :search="search"
          :preloader="fetchingData"
        />
        <p class="mt-4 caption">
          <v-icon small color="primary" title="Kampagne">
            mdi-alpha-c-circle
          </v-icon>
          : Kampagne<br />
          <v-icon small color="purple" title="mitfitMember">
            mdi-alpha-m-circle
          </v-icon>
          : mitfitMember<br />
          <v-icon small color="blue" title="Facebook LeadAD">
            mdi-facebook
          </v-icon>
          : Facebook LeadAD<br />
          <v-icon small color="pink" title="mitfitPay">
            mdi-alpha-p-circle
          </v-icon>
          : mitfitPay
          <br />
          <v-chip
            x-small
            color="blue-grey"
            class="white--text"
            title="Direktkauf"
          >
            DK
          </v-chip>
          : Direktkauf
          <br />
          <v-chip
            x-small
            color="teal"
            class="white--text"
            title="Jahresmarketing"
          >
            JMP
          </v-chip>
          : Jahresmarketing
        </p>
      </v-col>

      <v-col cols="4">
        <Subnavigation
          root="/tech/campaign_management/campaigns"
          :pageTitle="pageTitle"
        ></Subnavigation>
      </v-col>
    </v-row>

    <AreYouSureDialog
      title="Soll die Kampagne gelöscht werden?"
      text="Wenn du die Kampagne tatsächlich löschen möchtest, klicke auf 'Ja'. Ansonsten klicke auf 'Nein'."
      :dialog="dialog"
      @dialogEmit="dialogEmit"
    />
  </div>
</template>

<script>
import apiRequest from "@/mixins/apiRequest";
import PageHeader from "@/components/ui/PageHeader.vue";
import AreYouSureDialog from "@/components/ui/AreYouSureDialog.vue";
import Subnavigation from "@/components/ui/Subnavigation.vue";
import DataTable from "@/components/ui/DataTable.vue";
export default {
  data() {
    return {
      pageTitle: "Aktive Kampagnen",
      pageDescription: "Kampagnen, die aktuell aktiv laufen",
      headers: [],
      items: [],
      edit: true,
      trash: true,
      preview: true,
      copy: false,
      search: "",
      fetchingData: false,
      dialog: false,
      stats: true,
      currentItemId: null,
      currentItemIndex: null,
    };
  },

  created: function () {
    this.getData(); // call it immediatly
  },

  methods: {
    async deleteItem() {
      /* da ich die ID und den INDEX vorher den currentXX Vars zugewiesen habe, kann ich diese nun verwenden */

      let response = await this.deleteRequest(
        "campaigns/" + this.currentItemId
      ); // await data from mixin

      if (response.status === 200) {
        this.items.splice(this.currentItemIndex, 1); // remove from array
      }
    },

    dialogEmit(value) {
      if (value) {
        /* ANSWER: TRUE */

        this.deleteItem();
        this.dialog = false;

        /* start snackbar */
        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText =
          "Der Datensatz wurde erfolgreich gelöscht.";
        this.$root.snackbarGlobal.snackbarColor = "green";
        this.$root.snackbarGlobal.snackbarTimeout = 10000;
      } else {
        /* ANSWER: FALSE */

        this.dialog = false;

        /* start snackbar */
        this.$root.snackbarGlobal.snackbar = true;
        this.$root.snackbarGlobal.snackbarText =
          "Der Datensatz wurde nicht gelöscht.";
        this.$root.snackbarGlobal.snackbarColor = "green";
        this.$root.snackbarGlobal.snackbarTimeout = 10000;
      }
    },

    async emittedAction(value) {
      if (value.action === "deleteItem") {
        /* da wir einen dialog zwischenschalten, muss ich den index und die item id speichern, damit ich diese im Anschluss löschen kann.
        Das löse ich hier mit currentItemId und currentItemIndex */

        this.currentItemId = value.itemId;
        this.currentItemIndex = value.index;

        this.dialog = true; // starte den Dialog. Rückgabe erfolgt via emit nach dialogEmit()
      } else if (value.action === "editItem") {
        this.$router.push({
          name: "campaign-management.campaigns.:id",
          params: { id: value.itemId },
        });
      }
    },

    async getData() {
      this.fetchingData = true;
      let response = await this.getRequest("campaigns?filter[status]=active"); // await data from mixin
      this.fetchingData = false;
      // if complete (response, data or false)

      // prefix response.data.data.name with [TEST] when response.data.data.isTest === 1
      response.data.data.forEach((item) => {
        if (item.isTest === "1") {
          item.name = "[TEST] " + item.name;
        }
      });

      if (response) {
        this.headers = [
          { text: "ID", value: "id" },
          /* { text: "Quelle", value: "planMonths_id" }, */
          { text: "Name", value: "title" },
          { text: "Arbeitstitel", value: "name" },
          { text: "Type", value: "campaignType", sortable: false },
          { text: "Kunde", value: "company.name" },
          /* { text: "Start", value: "dateStart" }, */
          { text: "Ende", value: "dateEnd" },
          { text: "Stats", value: "quickLeadStatistic" },
          { text: "Actions", value: "actions", sortable: false },
        ];

        this.items = response.data.data.reverse();
        (this.edit = true),
          (this.trash = true),
          (this.copy = true),
          (this.preview = true),
          (this.search = "");
      }
    },

    async createNew() {
      //alert("Neue Kampagne würde jetzt erstellt werden!");

      /* create new entry */
      let response = await this.postRequest("campaigns"); // await data from mixin
      //console.log(response);

      let newItem = response.data.data;
      this.items.splice(0, 0, newItem); // add to array at index 0
    },
  },

  components: {
    PageHeader,
    Subnavigation,
    DataTable,
    AreYouSureDialog,
  },

  mixins: [apiRequest],
};
</script>
